.auctions {
    background-image: url("../../../assets/images/Auction.png");
    height: 250px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

p.title {
    font-weight: 700;
    font-size: 32px;
    margin: 0;
}

p.subtitle {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.hot-auctions {
    color: #2695FF;
    font-size: 20px;
    font-weight: 500;
}

button.view-all-btn {
    padding: 6px 25px;
    background: transparent;
    border: 1px solid #2695FF;
    color: #838383;
    font-size: 14px;
    border-radius: 6px;
}

/* .woodburn1 {
    background: #F2F2F2 !important;
} */

.woodburn-card {
    background: #F2F2F2 !important;
    height: 100%;
    border: none;
}

.woodburn1 img {
    width: 100%;
    height: 100%;
}

.woodburn-text {
    color: #787474;
    font-size: 14px;
    font-weight: 500;
}

.woodburn-text-heading {
    font-size: 16px;
    font-weight: 700;
}

.woodburn1{
    align-items: center!important;
}

.category-images img {
    object-fit: contain !important;
    height:100px;
    width: 100px;
}

@media (min-width: 768px) {
    .text-md-right {
        text-align: right;
    }
}

.category-images {
    text-align: center;
}

.category-card {
    border: 0px;
}

.auctions-category {
    color: #2695FF;
    font-size: 18px;
    font-weight: 500;
}

.category-text-heading {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.category {
    background: #F9F9F9;
}

.online-auction {
    color: #2695FF;
    font-size: 28px;
    /* font-weight: 500; */
}

.card-body{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-direction: column;
}
/* .card-body.category-cards {
    max-height: 150px;
} */

@media (max-width: 767.98px) {
    h4.auctions-category.mt-2.text-uppercase {
        text-align: center !important;
    }
    .online-auction{
        font-size: 18px;
    }
}

.category-card {
    transition: box-shadow 0.3s ease-out;
    height: 170px;
}

.category-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .woodburn-card-data-card {
    transition: box-shadow 0.3s ease-out;
}

.woodburn-card-data:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.woodburn1-img img {
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    object-fit: contain;
    mix-blend-mode: darken;
}

.woodburn1-img img:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

