.user-details-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
}

.user-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.user-details-header h1 {
    font-size: 2rem;
    color: #333;
    margin: 0;
}

.user-details-header .btn {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.user-details-header .btn:hover {
    background-color: #0056b3;
}

.user-details-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-details-section {
    margin-bottom: 20px;
}

.user-details-section h2 {
    /* border-bottom: 2px solid #007bff; */
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.user-details-card {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-details-card p {
    margin: 10px 0;
}

.roles-list {
    list-style-type: none;
    padding: 0;
}

.role-item {
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.role-item:last-child {
    border-bottom: none;
}

.loading {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 50px;
}