a.header-item.header-list.auctioner-header {
  text-decoration: none;
}

.header-item-list.d-flex.text-uppercase.ml-md-3 {
  text-align: center;
  justify-content: center;
  padding: 8px 0px 5px 0px;
}

a.username-header {
  list-style: none;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submenu-arrow {
  margin-left: auto;
  font-size: 12px;
  transition: transform 0.3s ease;
}

.nav-item {
  position: relative;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav .pl-3 {
  padding-left: 1rem;
}

.nav {
  padding-left: 0;
  margin: 0;
}

.nav .nav-item {
  padding-left: 1.5rem;
}

.nav .nav-item .nav-link {
  padding: 8px 0;
  font-weight: bold;
  font-size: 16px;
}

.sidebar-toggle-btn {
  display: none;
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.close-sidebar-btn {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.super-admin-sidebar {
  display: none;
}

.super-admin-sidebar.open {
  display: block;
}

.mobile-sidebar {
  height: 100%;
  background: #343a40;
}

@media (max-width: 768px) {
  .sidebar-toggle-btn {
    display: block;
  }

  .sidebar-header {
    align-items: start !important;
  }

  .login-bidder {
    display: block !important;
  }

  .sidebar-content {
    position: relative !important;
  }

  .mobile-sidebar {
    height: 80px;
    margin-top: 10px;
  }

  .super-admin-sidebar.collapsed {
    display: none;
  }

  .super-admin-sidebar.open {
    display: block;
  }

  .super-admin-sidebar {
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }

  .super-admin-sidebar.open {
    transform: translateX(0);
  }

  .super-admin-sidebar {
    width: 100% !important;
    position: absolute;
    z-index: 999;
  }
}
