.add-subcategory {
    text-align: end;
}


.pagination-controls {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination-controls button {
    margin: 0 5px;
}