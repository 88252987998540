.top-picks-container {
  padding: 20px;
}

.countdown-timer {
  color: white;
  background: red;
  padding: 3px 0;
  width: 50px;
  display: inline-block;
  text-align: center;
}

.view-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.view-icon {
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.view-icon.active {
  color: #007bff;
}

.data-container {
  position: relative;
}

.list-view,
.grid-2-view,
.grid-12-view {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.list-view .data-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.grid-2-view .grid-item {
  width: 25%;
  /* width: calc(50% - 20px); */
  /* margin-bottom: 20px; */
}

.grid-12-view .grid-12-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.grid-12-item-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-12-item-content {
  padding: 10px;
}

.star-icon.filled {
  color: gold;
}

.star-icon.empty {
  color: gray;
}

.bid-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  background: none;
  border: 1px solid #007bff;
  color: #007bff;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 5px;
}

.page-button.active {
  background-color: #007bff;
  color: #fff;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.grid-item {
  /* border: 1px solid; */
}

.data-item.list-view {
  /* border: 1px solid; */
}

.grid-12-item {
  border: 1px solid;
}

@media only screen and (max-width: 576px){
  .time-counter{
    margin-left: 20px;
  }
}
