.side-header{
    background-color: #6C757D; 
}
.super-admin-sidebar {
    min-height: 100vh;
    background-color: #6C757D;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.sidebar-content {
    padding: 20px;
}

.d-flex.align-items-center {
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 10px;
}

.ml-2 {
    margin-left: 8px;
}

.login-bidder {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 0;
}

.sidebar-nav .nav-link {
    color: #fff;
    margin-bottom: 10px;
    transition: color 0.2s;
    text-decoration: none;
}

.sidebar-nav .nav-link:hover {
    color: #ffc107;
    font-weight: bold;
}

.sidebar-nav .nav-link.active {
    color: #fff;
    font-weight: 700;
}

.nav-link {
    text-decoration: none;
    color: #000;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-link.active {
    color: #007bff;
}

.submenu-arrow {
    margin-left: auto;
    font-size: 12px;
    transition: transform 0.3s ease;
}

.nav-item {
    position: relative;
}

.nav-item .nav-link {
    cursor: pointer;
}

.nav-item .nav-link.active {
    font-weight: bold;
    color: #ffc107;
}

.nav .pl-3 {
    padding-left: 1rem;
}

.nav .nav-item ul {
    padding-left: 0;
    margin: 0;
}

.nav .nav-item ul .nav-item {
    padding-left: 1.5rem;
}

.nav .nav-item ul .nav-item .nav-link {
    padding: 8px 0;
    font-weight: normal !important;
    font-size: 16px;
}

.nav .nav-item ul .nav-item .nav-link.active {
    font-weight: bold;
    color: #ffc107;
}