.footer {
  padding-top: 3rem;
  /* padding-bottom: 1rem; */
  background-color: #232323;
}

.footer .hire {
  padding: 0 10px;
  background-color: #2695ff;
}

.footer p {
  margin: 0;
}

.auctioned {
  border-right: 1px solid #fff;
  color: #fff;
}

.textttt {
  color: #fff;
}

.listed {
  text-align: center;
}

.listed-data {
  text-align: left;
}

.listed-data {
  text-align: right;
  padding-right: 40px;
}

.last-number {
  padding-left: 50px;
}

.value {
  padding-top: 13px;
  padding-bottom: 12px;
}

.lots {
  font-size: 20px;
}


@media (max-width: 768px) {
  .footer .col-md-4,
  .footer .col-md-2 {
    padding: 10px 0;
  }

  .listed-data {
    text-align: center;
  }

  .listed-data {
    text-align: center;
    padding-right: 0px;
  }

  .textttt.value {
    text-align: center;
  }

  p.last-number.lots {
    padding: 0px;
  }

  .auctioned.listed-data.value {
    border-bottom: 1px solid;
    border-right: 0;
  }

  .auctioned.listed.value {
    border-bottom: 1px solid;
    border-right: 0;
  }

  .col-md-4.footer-links-services {
    padding-left: 0 !important;
  }

  .calling-img img,
  .message-img img,
  .send-img img,
  .clock-img img {
    width: 100% !important;
    height: 100% !important;
  }

  .contact-btns-footer-mobile {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    width: 100% !important;
    gap: 10px;
  }
}

.links-useful {
  color: #2695ff;
}

.reasons {
  color: #fff;
}

li.useful-list {
  color: #fff;
  list-style: none;
}

.links {
  padding-top: 18px;
  padding-left: 0;
  cursor: pointer;
}

.calling-img {
  padding: 5px;
  border-radius: 50%;
}

.calling-img img {
  width: 100%;
  height: 100%;
}

.message-img img {
  width: 100%;
  height: 100%;
}

.footer-img img {
  transform: scale(2.4);
}

p.number.lots {
  font-weight: 500;
  font-size: 25px;
}

p.auctioned-lots {
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .row.footer-services {
    padding: 0;
    text-align: center;
  }

  .useful {
    padding-top: 20px;
  }

  .calling-img {
    background: transparent;
  }
}

.printing {
  color: #fff;
  font-size: 15px;
}

.footer-email {
  color: #fff;
}

.footer-icons {
  color: white !important;
  font-size: 20px;
}

.footer-icon-box {
  vertical-align: middle;
}

@media (max-width: 767.98px) {
  .footer-contact-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timing span {
    text-align: left !important;
  }

  p.text-left.reserved {
    text-align: center !important;
  }

  .clock-img {
    padding-top: 10px;
  }  
}

.timing {
  color: #fff;
  font-size: 15px;
}

.reasons {
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}

p.links-useful {
  font-size: 18px;
  font-weight: 600;
}

.services p,
.footer-contact-mobile p {
  font-size: 18px;
  font-weight: 600;
  color: #2695ff;
}

li.useful-list {
  font-weight: 400;
  font-size: 15px;
  padding-top: 9px;
}

.hr-line-footer {
  color: #ffff;
}

.reserved {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.footer-icon ul {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.footer-icon .social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.footer-icon .social-icons li {
  display: inline-block;
}

.call-icon {
  display: flex;
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .footer-icon ul {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  hr.hr-line-footer {
    margin-top: 12px;
    width: 90% !important;
  }

  ul.social-icons {
    margin-top: 15px !important;
  }
  .contact-box-text-mobile {
    font-size: 16px;
  }
  .call-icon {
    color: white !important;
  }
}

.links {
  list-style-type: none;
  padding: 0;
}

.links li {
  margin-bottom: 10px;
}

.links li .link {
  text-decoration: none;
  color: #fff;
}

.col-md-4.footer-links-services {
  padding-left: 150px;
}

@media only screen and (max-width: 576px) {
  .col-md-4.footer-links-services {
    margin-top: 38px;
  }
  .footer-links-services .services {
    text-align: left;
    padding-left: 20px;
  }
  .footer-contact-mobile{
    align-items: start;
  }
  .footer-contact-mobile p{
    margin-bottom: 1rem;
  }
}
