input.form-control.event-zip-code {
    padding: 0px;
    border: 1px solid #2695FF !important;
}

.state-online-auction{
    font-size: 1.2rem;
    font-weight: 600;
    color: #2695FF;
}

/* .toggle-icon {
    background: black;
    color: #fff;
    width: 23%;
    font-size: 21px;
    margin-bottom: 16px;
    text-align: center;
    cursor: 'pointer';
    padding: 5px;
    border-radius: 5px;
} */

.map-events {
    background: #eeeeee;
    padding: 15px 9px 8px 9px;
}

.event-search {
    background: #2695ff;
    color: #fff;
    border: 1px solid;
}

.sidebar-item.text-start {
    display: flex;
}

input.form-control.search-the-event {
    padding: 0px;
    border: 1px solid #2695FF !important;
}

.sidebar-item.text-start.jsutify-content-space-between {
    justify-content: space-between;
}

.lots-status {
    justify-content: space-between;
}

.auction-states-drop {
    justify-content: space-between;
}


/* svg.svg-inline--fa.fa-angle-down.toggle-icon.quick-search {
    color: black;
    background: transparent;
    width: 100%;
    font-size: 32px; 
} */

/* svg.svg-inline--fa.fa-angle-up.toggle-icon.quick-search {
    color: black;
    background: transparent;
    width: 100%;
} */


.search-event-lots {
    border-left: 0px ! IMPORTANT;
    border-radius: 0px;
}

.auction-sidebar {
    padding: 0px;
    border-top: 1px solid #2695FF;
}


.d-flex {
    display: flex;
}

.justify-content-end {
    justify-content: flex-end;
}

.image-lots img {
    width: 65%;
    height: 100%;
}

.item {
    transition: box-shadow 0.3s ease-out;
}

.item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.shadow-sm {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.jif img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.lots-form-group ul{
padding: 0;
  
}
.lots-form-group ul .sidebar-item{
padding-left: 50px !important;
  
}

.realtors span {
    cursor: pointer;
}

.cuckoo {
    /* border: 1px solid #2695FF; */
    min-height: 100%;
    
}

.clock {
    background: #f5f5f5;
}


.cuckoo-clock {
    padding: 0px;
    font-size: 18px;
    bottom: 140px;
}

.bid-rate {
    padding: 0px;
    font-size: 18px;
    bottom: 140px;
}

.btn-toogle {
    justify-content: end;
}

.auctions-btnss button {
    border-radius: 12px;
}

.map-btns button {
    border-radius: 12px;
}

.lots-btns button {
    border-radius: 12px;
}

.schneider span {
    cursor: pointer;

}











.sidebar-item-category{
    border-bottom: 1px solid #0d6efd;
}

/* .sidebar-item .form-group label{
    font-weight: inherit;
}
 */



/* Styles for largeGrid view */
.largeGrid .item {
    border: 1px solid #ddd;
    /* 1px border around each item */
    padding: 10px;
    /* Padding inside the item */
    margin-bottom: 20px;
    /* Gap between rows */
}

.largeGrid .item img {
    width: 100%;
    /* Make image fill the container */
    height: auto;
}

.largeGrid .item .text-center {
    text-align: center;
}

.largeGrid .item .pt-5 {
    text-align: center;
    margin-top: 10px;
}

.largeGrid .item .description {
    margin-bottom: 10px;
}

.largeGrid .item p {
    margin-bottom: 5px;
}

.largeGrid .item .end-date {
    font-weight: bold;
}

/* MainDeivLots.css */

.item-list {
    display: flex;
    align-items: center;
    /* border: 1px solid #ddd; */
    padding: 10px;
    margin: 0;
    list-style: none;
}

.item-list.even {
    background-color: #f9f9f9;
}

.item-list.odd {
    background-color: #fff;
}

.item-image {
    width: 100px;
    height: auto;
    margin-right: 15px;
}

.item-content {
    display: flex;
    align-items: center;
}

.item-details {
    flex: 1;
}

.item-details p {
    margin: 5px 0;
}

.item-details button {
    display: block;
    margin-top: 10px;
}



.image-lots {
    position: relative;
    overflow: hidden;
}

.image-lots img {
    width: 100%;
    height: auto;
}

.lens {
    display: none;
    position: absolute;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: width 0.2s ease, height 0.2s ease;
}

.image-lots:hover .lens {
    display: block;
}

@media (max-width:768px){
    .btn-toogle>div{
        flex-direction: row;
    }
}