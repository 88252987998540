/* button.user-register-btn {
    background: #fff;
    padding: 12px 20px;
    color: blue;
    border: 1px solid blue;
    font-size: 19px;
} */

.sign-up-auctioner{
    /* box-shadow: 1px 1px 9px #2695ff,-1px -1px 9px #2695ff !important; */
    background: #2695ff !important;
    color: #fff !important;
    /* border: 2px solid #2695ff !important; */
    padding: 10px 49px !important;
    font-size: 19px !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 170px;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    font-weight: 400 !important;
    
}

.sign-up-auctioner:hover {
    
    box-shadow: 1px 1px 5px gray;
    transform: scale(1.01);    
}