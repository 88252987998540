.main-div-data {
  padding: 30px;
  border: 1px solid #2695ff;
  border-radius: 12px;
}

.prebidding {
  justify-content: end;
}

.images-data img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.davis-brother {
  font-size: 15px;
  word-wrap: break-word;
}

.btn-auction {
  background: #0d6efd;
  color: #fff;
  border: 1px solid #0d6efd;
  padding: 10px 14px 10px 13px;
  border-radius: 7px;
}

.btn-bid-live {
  background: #0d6efd;
  color: #fff;
  border: 1px solid #0d6efd;
  padding: 10px 14px 10px 13px;
  border-radius: 7px;
}

.btn-catalog {
  background: #0d6efd;
  color: #fff;
  border: 1px solid #0d6efd;
  padding: 10px 14px 10px 13px;
  border-radius: 7px;
}

.btn-hammer {
  background: #0d6efd;
  color: #fff;
  border: 1px solid #0d6efd;
  padding: 10px 14px 10px 13px;
  border-radius: 7px;
}

.refresh {
  border: 1px solid #0d6efd;
  margin-top: 13px;
  padding: 10px;
  box-shadow: 4px 2px 5px 0px lightblue;
  border-radius: 10px;
}

button.btn.btn-link.p-0 {
  text-decoration: none;
}

/* svg.svg-inline--fa.fa-angle-right.toggle-icon.quick-search {
  background: white;
  color: black;
  width: 100%;
} */

.sidebar-item.d-flex.lots-status {
  border-top: 1px solid #0d6efd;
}

.sidebar-item.d-flex.auction-states-drop {
  border-top: 1px solid #0d6efd;
}

.magnifier-image {
  object-fit: cover !important;
}

/* .magnifier{
    width: 100px !important;
    height: 100px !important;
} */

.category-border {
  /* border-top: 1px solid #0d6efd; */
  border-bottom: 1px solid #0d6efd;
  /* padding: 0px 10px; */
  margin: 0px;
  justify-content: flex-start !important;
}

.clock-category {
  border-top: 0px !important;
}

.sidebar-sub-sub-item {
  border: 1px solid #0d6efd;
  border-top: 0px;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: #2695ff;
  border-color: #2695ff;
  color: #fff;
}

.sidebar-item.d-flex.align-items-center.justify-content-between {
  border-top: 1px solid #0d6efd;
}

@media (max-width: 768px) {
  .auction-data-system {
    padding: 0px;
    text-align: center;
    padding: 15px 0px;
  }

  .auction-data-title {
    text-align: center !important;
  }

  .refresh {
    margin: 12px 12px;
  }

  .prebidding-open-btn {
    flex-direction: row;
  }

  .detail-div {
    flex-wrap: wrap;
  }

  .images-data img,
  .images-data .magnifier {
    width: 200px !important;
    min-width: 200px !important;
  }
  .bid-btns {
    flex-wrap: wrap;
  }

  .btn-catalog,
  .btn-hammer {
    padding: 10px;
  }

  .btn-bid-live {
    padding: 10px 6px;
  }
}

@media only screen and (max-width: 576px){
  .short-col{
    justify-content: start !important;
  }
}
