.slider-container {
    position: relative;
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 500px !important;
}

.slider-image-wrapper {
    height: 100%;
    width: 100%;
    max-height: 300px !important;
    text-align: center;
} 

.lotsDetailsSlider.magnifier {
    max-height: 300px !important;
}

.lotsDetailsSlider .magnifier img.magnifier-image {
    max-height: 300px !important;
    object-fit: contain !important;
}

.lotsDetailsSliderThumbnail .slick-list {
    max-height: 100px !important;
}

.lotsDetailsSliderThumbnail .slick-list .slick-track {
    max-height: 110px !important;
}

.lotsDetailsSliderThumbnail .slick-list .slick-track .slick-slide {
    width: 150px !important;
    max-height: 150px;
}

ul.slick-dots {
    display: none !important;
}

.lotsDetailsSliderThumbnail .slick-list .slick-track .slick-slide img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.slider-image {
    max-height: 500px;
    object-fit: cover;
}

.additional-images {
    margin-top: 20px;
}

.additional-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.additional-image {
    width: 100px;
    height: auto;
    object-fit: cover;
}

.collapse-button {
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;
}

.collapse-section {
    margin-bottom: 20px;
}

.thumbnail-slider {

    margin-top: 10px;

    .slick-track {
        display: flex;
        align-items: center;
    }
}

.thumbnail-slider .slick-slide {
    margin: 0 10px;
}

.thumbnail-img {
    cursor: pointer;
    width: 100%;
    max-height: 200px !important;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.thumbnail-img:hover {
    border-color: #007bff;
}


.slick-slide img {
    width: 100%;
}

.slick-next{
    right: -16px;
}