.watchlist-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}


.home-arrow {
    font-size: 1.5rem;
    color: #007bff;
}

.selected-button {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.button {
    background: #f8f9fa;
    border: 1px solid #dee2e6;

    padding: 10px 20px;
    cursor: pointer;
    flex: 1 1 auto;
    text-align: center;
    transition: background 0.3s, color 0.3s;
    font-size: 1rem;
    font-weight: bold;
}

.button:hover {
    background: #007bff;
    color: #fff;
}

.button.active {
    background: #007bff;
    color: #fff;
    border-color: #007bff;
}

.info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-item {
    flex: 1;
    text-align: center;
}

.info-item p {
    margin: 5px 0;
    font-size: 1rem;

}

.button-group {
    display: flex;
    gap: 10px;
}

.colored-button {
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.colored-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.colored-button.green {
    background: #28a745;
}

.colored-button.brown {
    background: #795548;
}

.colored-button.red {
    background: #dc3545;
}

.colored-button.blue {
    background: #007bff;
}

.colored-button.print {
    background: #343a40;
}

.tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
}

.colored-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
    bottom: 130%;
}

@media (max-width: 768px) {
    .info-box {
        flex-direction: column;
        align-items: center;
    }

    .button-group {
        flex-direction: column;
        gap: 10px;
    }

    .mobile-button-group-watchlist {
        flex-direction: row !important;
    }

    .mobie-screen-data {
        flex-direction: column;
    }


    .currents-bids-images {
        width: 100%;
    }

    .current-bids-data-watchlist {
        width: 100%;
    }

    .shipping-current-bids {
        width: 100%;
    }

    .data-item .image-column img {
        max-width: 100% !important;
    }

    .mobile-single-view {
        flex-direction: column;
        gap: 10px !important;
    }

    .mobile-single-description {
        flex-direction: column;
        width: 100%;
    }

    .single-view-accept {
        flex-direction: column;
        gap: 1px !important;
    }

    .mobile-singledesc-data {
        width: 100%;
    }

    .mobile-singledesc-border {
        border: none;
    }



    .watchlist-data {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .watchlist-data-lot {
        width: 100%;
    }

    .watch-list-single-view-data {
        flex-direction: column !important;
    }

    .watch-list-single-view-images {
        flex-direction: column !important;
        gap: 10px !important;
    }

    .flex.items-center.justify-start.gap-3.w-3\/5.h-full {
        flex-direction: column;
        width: 100% !important;
    }

    .watch-list-single-view-desc {
        width: 100%;
    }

    .watch-list-single-address {
        border: none;
    }

    .watch-list-single-top-picks-address {
        border: none;
        width: 100%;
    }
.button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
    
}