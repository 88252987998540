.letter {
  font-size: 18px;
  text-align: center;
  display: inline-block;
  margin: 0px 8px;
}

.slick-slide > div {
  margin: 0 12px;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.disabled-letter .letter.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.category-scroll {
  scrollbar-width: none !important;
}

/* .auctions-card-images{
    display: flex;
} */
