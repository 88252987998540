.company-details .edit-btn {
  display: flex;
  justify-content: end;
}
.company-details .edit-btn .edited {
  width: max-content;
  padding: 10px 20px;
}

.edit-company .modal-content {
  max-height: 100% !important;
}

@media only screen and (max-width: 768px) {
  .company-details .row {
    padding: 0 !important;
  }

  .company-details .edit-btn {
    margin-top: 10px;
  }

  .company-details .details {
    width: 100% !important;
    align-items: start !important;
  }

  .edit-company .modal-content {
    overflow: scroll !important;
    min-width: 100% !important;
    max-height: 650px !important;
  }

  .edit-company input.form-control {
    max-width: 100% !important;
    width: 100% !important;
  }
}
