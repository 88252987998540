.row.auction-home {
  background: #b7e1e4;
}

h5.state-online-auction {
  font-size: 18px;
  color: #2695ff;
}

.auction-home {
  padding: 0;
}

.text-start {
  padding-left: 0;
}

.toggle-btn {
  margin: 0 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  transition: background-color 0.3s;
}

.toggle-btn.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.map-container {
  text-align: center;
}

.states-hover-bg:hover .state-province-name {
  color: white;
  font-weight: 600;
}

/*state auction pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #007bff;
  color: #fff;
}

.pagination-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.pagination-ellipsis {
  margin: 0 5px;
  font-size: 1.2rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* Adjust as needed */
}

.pagination-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.pagination-btn {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #007bff;
  color: #fff;
}

.pagination-ellipsis {
  margin: 0 5px;
  font-size: 1.2rem;
}

/**/
.search-bar {
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input.search-bar.state-searchbar {
  width: 50% !important;
}
