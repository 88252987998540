.edit-info form {
  width: 60%;
  max-width: 100%;
  margin: auto;
  background-color: rgb(248, 248, 248);
}

.edit-info form input,
.edit-info form select{
  background-color: #f3f3f3;
}

@media only screen and (max-width: 768px) {
  .edit-info form {
    width: 100%;
  }

  .edit-info form input.form-control {
    width: 100% !important;
    max-width: 100% !important;
  }
}
