/* #chat-container {
  gap: 10px;
}

.message-input {
  border: 1px solid;
  padding: 7px;
  width: 85% !important;
  margin-top: 5px;
}

.send-message {
  width: 15%;
  background: #2695ff;
  color: #fff;
}

#chat-container {
  max-height: 300px;
  min-height: 100px;
  padding: 25px;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

/* video css*/
.webcam-modal {
  position: fixed;
  top: 190px;
  right: 20px;
  width: auto;
  height: auto;
  z-index: 1000;
  pointer-events: none;
}

.webcam-modal-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webcam-feed {
  /* height: 240px; */
  border: 1px solid #000;
}

/* .close-webcam {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
} */
