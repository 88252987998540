.user-agreeement {
    font-size: 18px;
}

.facilitate {
    font-size: 18px;
}

.auctioneer-file {
    font-size: 24px;
}

.facilitate-location {
    padding-left: 15px;
}

.facilitate-location {
    padding-left: 80px !important;
}