.form-label-admin {
    font-weight: bold;
}

.email-form {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.end-email-admin {
    margin-top: 10px;
}



.invalid-feedback.body-error {
    display: block;
    color: red;
    padding-top: 0px;
}

.quill-editor {
    position: relative;
}

.quill-editor .ql-container {
    min-height: 150px;
}