.sidebars {
    padding: 20px;
    background: #f8f9fa;
    border-right: 1px solid #ddd;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.refresh-btns {
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
}

.refresh-btns:hover {
    background: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.refresh-icon {
    margin-right: 10px;
}



.section-headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    background: #e9ecef;
    border-radius: 5px;
    transition: background 0.3s;
}

.section-header:hover {
    background: #ced4da;
}

.toggle-icon {
    font-size: 1rem;
}

.section-content {
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

.auction-select {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.radio-group label,
.checkbox-group label {
    display: block;
    margin-bottom: 10px;
}

.radio-group input,
.checkbox-group input {
    margin-right: 10px;
}

.checkbox-group {
    margin-top: 10px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
}