.reset-password form {
  width: 40%;
  max-width: 100%;
  margin: auto;
}

.reset-password form label{
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: normal;
}

@media only screen and (max-width: 768px) {
  .reset-password form {
    width: 100%;
  }
}
