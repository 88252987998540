.main-data {
    padding: 20px;
}

.sort-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sort-options {
    /* display: flex; */
    /* justify-content: end; */
    /* gap: 15px; */
}

.sort-dropdown,
.direction-dropdown {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.view-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.view-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    gap: 5px;
}

.view-button:hover {
    background-color: #0056b3;
}

.view-button.active {
    background-color: #0056b3;
}

.button-text {
    font-size: 0.9rem;
}

.info-message {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
    color: #666;
}


.view-button.active {
    background-color: #fff;
    color: #000;
    border: 1px solid;
}

button.view-button {
    background-color: #fff;
    color: #000;
    border: 1px solid;
}

.main-data .row.list .data-item {
    display: flex;
    align-items: center;
}

.main-data .row.grid-2 .data-item,
.main-data .row.grid-12 .data-item {
    text-align: center;
    margin-bottom: 20px;
}

.main-data .data-item img {
    width: 100%;
    height: auto;
}


.main-data {
    padding: 20px;
}

.sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort-options {
    display: flex;
    align-items: center;
}

.view-button {
    background: none;
    border: none;
    cursor: pointer;
}

.view-button.active {
    color: #007bff;
}


svg.svg-inline--fa.fa-ban path{
    fill: red !important;
}


.data-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #ddd; */
}

/* .data-item .image-column img {
    max-width: 100px;
    max-width: 200px;
    min-height: 150px;
    object-fit: cover;
} */

.data-item .description-column {
    flex: 2;
}

.data-item .date-column,
.data-item .amount-column,
.data-item .shipping-column {
    flex: 1;
}

.data-box-list {
    justify-content: space-between;
    padding: 10px;
    /* border: 1px solid; */
    margin-bottom: 7px;
    border-radius: 10px;
    width: 100%;
}


.data-box-grid-2 {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    gap: 15px;
    min-height: 100%;
    min-width: 100%;
}

/*.data-box-grid-2 .item-image img {
    max-width: 100%;
}

.data-box-grid-2 .item-details {
    margin-top: 10px;
} */

/* Grid-12 View */
.data-box-grid-12 {
    /* border: 1px solid #ddd; */
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
}

.data-box-grid-12 .item-header {
    margin-bottom: 10px;
}

.data-box-grid-12 .item-details {
    display: flex;
    justify-content: space-between;
}

.detail-column {
    flex: 1;
    /* margin-right: 10px; */
}

.detail-column img {
    /* max-width: 100%; */
}

.ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 6em;
    text-align: justify;

}

.watchbids-image img {
    width: 60% !important;
}







.item-image img {
    max-width: 100%;
    height: auto;
}




.item-actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.star-icon {
    font-size: 24px;
}

.star-icon.filled {
    color: gold;
}

.star-icon.empty {
    color: gray;
}