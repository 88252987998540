.configuration-table {
    /* background-color: #000; */
    border-radius: 8px;
    overflow: hidden;
}

.configuration-table th,
.configuration-table td {
    text-align: center;
}

.configuration-table .btn {
    border: none;
    background: none;
    cursor: pointer;
}

.configuration-table .btn:hover {
    opacity: 0.7;
}

.alert-info {
    border-color: #d1ecf1;
    background-color: #d1ecf1;
    color: #0c5460;
}

.alert-danger {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.configuration-table {
    transition: opacity 0.5s ease-in-out;
}

.container.fade-out .configuration-table {
    opacity: 0.5;
}

.container.fade-in .configuration-table {
    opacity: 1;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner-border-custom {
    width: 3rem;
    height: 3rem;
    border: 0.4em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 0.4em solid #007bff;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fade-out {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}