
.userdetail-section label {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;   
    margin-bottom: 0; 
  }
  
  .userdetail-section input,
  .userdetail-section select {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }