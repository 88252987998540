*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sideauction-header{
    background-color: #2695FF;
    height: 100vh;
}

.sideauction-header .nav .nav-item .nav-link{
    font-size: 18px;
}