p.email-address {
    font-size: 15px;
}

.forgot-modal {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #ccc;
}

.button.btn.btn-primary.btn-lg {
    width: 100%;
}