.slider-card {
  height: 380px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fafafa;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.slider-image img {
  height: 250px;
  width: 100%;
  object-fit: contain;
  mix-blend-mode: darken;
}

.slider-text h4 {
  color: #007bff;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: normal;
  /* height: 60px; */
  -webkit-line-clamp: 1;
}

.banner-slide .slick-prev:before,
.banner-slide .slick-next:before {
  color: #6c757d !important;
  font-size: 28px;
}
