.sellerfees p,
.sellerfees a{
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: normal;
}

.sellerfees ol li{
    list-style: auto;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: normal;     
}