.information-contact {
    border: 1px solid #ddd;
    margin-bottom: 16px;
    background: #f5f5f5
}

.auction-online {
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 10px;
}

.company-auction-notice {
    border: 1px solid #ddd;
}

.comapny-auction-image {
    width: 100%;
    height: auto;
}

.bidding-notice {
    /* border: 1px solid #007bff; */
    /* border: 1px solid ; */
    padding: 15px;
}