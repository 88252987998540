.main-div {
    padding: 20px;
}

.d-flex {
    display: flex;
    align-items: center;
}

.companies {
    margin-left: 10px;
}

i.fas.fa-arrows-up-down {
    color: #838383;
}

.show-dropdown {
    width: 150px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

@media (max-width: 768px) {
    .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.show {
    color: #2695ff;

}

.pagination-button {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid #2695ff;
    color: #2695ff;
    cursor: pointer;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -1px;
}

.pagination-button.selected {
    background-color: #2695ff;
    color: white;
}

.pagination-button.previous {
    color: #2695ff;
}



.pagination-button:focus {
    outline: none;
}

.pagination-button:hover {
    background-color: #2695ff;
    color: white;
}

.company-table {
    width: 100%;
    border-collapse: collapse;
}

.company-table th,
.company-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    position: relative;
}

.company-table th span {
    margin-right: 10px;
}

i.fas.fa-arrow-up {
    color: #838383;
}

i.fas.fa-arrow-down {
    color: #838383;
}

.company-table tbody tr:hover {
    background-color: #f1e7e2;
}

.company-table tbody td.underlined {
    text-decoration: underline;
}

.comapny-sorting {
    color: #2695ff;
    font-weight: 700;
    font-size: 18px;
}

.location-sorting {
    color: #2695ff;
    font-weight: 700;
    font-size: 18px;
}

p.showing-page-item {
    color: #2695ff;
}

/* */
/* .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
 
} */