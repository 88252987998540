.message-input {
  border: 1px solid #ccc !important;
  flex-grow: 1;
  padding: 10px !important;
  border-radius: 20px;
  margin-right: 10px;
}

.send-message {
  width: 15%;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-message:hover {
  background-color: #0056b3;
}

#chat-container {
  max-height: 300px;
  min-height: 100px;
  padding: 25px;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


#chat-part {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  width: 78%;
}

.message {
  display: inline-block;
  max-width: 70%;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  position: relative;
  font-size: 14px;
  word-wrap: break-word;
}

.message .username {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 12px;
}

.message .timestamp {
  font-size: 10px;
  color: #999;
  position: absolute;
  bottom: -15px;
  right: 10px;
}

.message.user {
  background-color: #e0f7fa;
  margin-left: auto;
  text-align: right;
}

.message.other {
  background-color: #eceff1;
  margin-right: auto;
}

#chat-part form {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.message-input:focus-visible {
  outline: none;
}

/* video css*/


.webcam-modal {
  position: fixed;
  top: 190px;
  right: 20px;
  width: auto;
  height: auto;
  z-index: 1000;
  pointer-events: none;
}

.webcam-modal-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end !important;
}

.webcam-modal-content .close-button svg{
  font-size: 25px !important;
}

.webcam-feed {
  /* height: 240px; */
  border: 1px solid #000;
}

.message-box {
  background-color: #2695ff;
  color: #fff;
}

.message-box::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  transform: rotate(45deg);
  top: -6px;
  background-color: #2695ff;
  left: 25px;
}

#chat-part {
  border: 1px solid #dddd;
  border-radius: 10px;
  padding: 10px;
}

/* .msg-bg-light {
    background-color: #f0f8ff;    
}

.msg-bg-dark {
    background-color: #d3d3d3;    
} */

/* .close-webcam {
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
} */

@media only screen and (max-width: 575px) {
  .send-message {
    width: auto !important;
  }

  #chat-part {
    width: 100% !important;
  }
}
