.country-table,
.state-table {
    margin-bottom: 20px;
}

.country-table th,
.state-table th {
    background-color: #f8f9fa;
    text-align: center;
}

.country-table td,
.state-table td {
    text-align: center;
}

.pagination {
    justify-content: center;
    margin: 20px 0;
}

td.country-list-name {
    cursor: pointer;
}