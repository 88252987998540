/* .sign-in-auctioner {
    background: #2695ff;
    color: #fff;
    padding: 6px 24px;
} */

.sign-in-auctioner:hover {
    transform: scale(1.01); 
    box-shadow: 1px 1px 5px gray;
    
}
.sign-in-auctioner{
    /* box-shadow: 1px 1px 9px #2695ff,-1px -1px 9px #2695ff !important; */
    background: #2695ff !important;
    color: #fff !important;
    border: 2px solid #2695ff;
    padding: 4px 49px;
    font-size: 24px;
    
}