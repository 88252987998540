.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-contents {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 80%;
    max-width: 800px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.slider {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-image {
    max-width: 100%;
    max-height: 100%;
}

.slider-button {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail {
    width: 60px;
    height: auto;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.6;
}

.thumbnail.active {
    opacity: 1;
    border: 2px solid #000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-contents {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 80%;
    max-width: 800px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ccc;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-image-slider {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative;
}

.modal-image {
    /* max-width: 100%; */
    /* max-height: 500px; */
    width: 100%;
    height: 100%;
}

.slider-button-modal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background: rgba(0, 0, 0, 0.5); */
    color: #000 !important;
    background-color: #dddddd !important;
    border: none;
    border-radius: 50%;
    /* padding: 10px; */
    /* min-height: 100%; */
    cursor: pointer;
    line-height: 0;
    margin: 0;
    padding: 15px !important;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 20px !important; */
}

.slider-button-modal:first-of-type {
    left: 0px !important;
}

.slider-button-modal:last-of-type {
    right: 0px !important;
}

.modal-details {
    /* margin-top: 20px; */
    display: flex ;
    align-items: start;
    justify-content: flex-start;
    gap: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    flex-direction: column;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-contents {
    background: white;
    padding: 50px 30px !important;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    max-width: 900px;
    width: 100%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #ccc;
    border: none;
    border-radius: 50%;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-bodys {
    display: flex;
    /* flex-direction: row; */
    width: 100%;
}

.modal-image-slider {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.modal-image {
    max-width: 100%;
    max-height: 500px;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.slider-button:first-of-type {
    left: 0;
}

.slider-button:last-of-type {
    right: 0;
}

.modal-details {
    /* flex: 1; */
    margin-left: 20px;
}

.modal-details h3 {
    margin-top: 0;
}



.confirm-bid-button:hover {
    background-color: #008CBA;
    box-shadow: 0.5px 0.5px 9px gray;
}


/* Ensure modal overlay covers the full screen */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Center the modal content */
.modal-contents {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
}

.modal-image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.modal-image {
    max-width: 100%;
    height: auto;
}

.slider-button {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
}

img.modal-image {
    width: 50%;
    height: 100%;
}

.bid-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.bid-input {
    width: 100%;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.increment-button {
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
}

.confirm-bid-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}

button.increment-button {
    padding: 5px !important;
}

.confirm-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.confirm-dialog-button {
    margin: 5px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
}

.confirm-dialog-button:hover {
    background-color: #45a049;
}