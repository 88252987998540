.pt-5.auction-images-upload-label {
    padding-top: 15px;
}

.file-images {
    padding-top: 10px;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated.css-11i1luh-MuiFormLabel-root-MuiInputLabel-root {
    padding: 14px 0 !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-eimhud-MuiSvgIcon-root {
    font-size: 29px !important;
}

.MuiFormControl-root.MuiTextField-root.css-l3mqh0-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.MuiFormControl-root.MuiTextField-root.css-19ne8jf-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.css-1xmq2ry-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    padding: 10px 0px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.delete-auction-lot.css-3nq4bb-MuiButtonBase-root-MuiButton-root {
    color: black;
    border: 1px solid;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.add-lot-btn.css-2dtspl-MuiButtonBase-root-MuiButton-root {
    color: #fff;
}


.MuiFormControl-root.MuiTextField-root.css-1sqs9ir-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    margin-bottom: 10px;
}

.remove-slab-btn {
    margin-bottom: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorError.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorError.remove-slab-btn.css-1c5duyb-MuiButtonBase-root-MuiButton-root {
    margin-bottom: 10px;
}

.slab-amount {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    box-shadow: none !important;
}


.pagination .pagi {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  
  .pagination button {
    color: #6c757d;
    cursor: pointer;
    padding: 4px 6px;
  }
  
  .pagination span {
    color: #fff;
    background-color: #007bff;
    padding: 5px 10px;
  }

/* .MuiTable-root td {
    padding: 0 16px!important;
} */

/* .MuiPaper-root svg {
    font-size: 24px!important;
} */


.pagination label{
    font-size: 16px;
    font-weight: 600;
}

.selected-row {
    background-color: rgba(0, 123, 255, 0.1) !important; /* Light blue */
  }
  
  .MuiCheckbox-root.Mui-checked {
    color: #1976d2 !important; /* Change the checkbox color when selected */
  }

  @media only screen and (max-width: 992px){
    .MuiTable-root .tss-1ej321f-MUIDataTableBodyCell-cellHide {
        display: none !important;
    }    
  }