.email-edit-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
}

.email-edit-form {
    display: grid;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-control-admin-email {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-control-admin-email:focus {
    border-color: #007bff;
    outline: none;
}

select.form-control-admin-email {
    height: 40px;
}

.btn-edit-email {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-edit-email :hover {
    background-color: #0056b3;
}

.email-table {
    width: 100%;
}

.email-table th,
.email-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email-table td {
    max-width: 200px;
    word-wrap: break-word;
}

.email-table td,
.email-table th {
    vertical-align: middle;
}

.quill-editor {
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
}

@media only screen and (max-width: 576px){
    .email-list{
        overflow-x: scroll;
    }
}