* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.lotdetail-section label {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  width: 30%;
}

.lotdetail-section input,
.lotdetail-section select,
.lotdetail-section textarea {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  width: 100%;
}
