.add-blog-btn{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.5rem;
    padding: 0 10px 0 0;
}

@media only screen and (max-width: 576px){
    .admin-blog .MuiTable-root td{       
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
    .admin-blog .MuiTable-root tr{
        display: flex;
    }
}