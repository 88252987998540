h1.search-auctioneers {
    color: #2695ff;
    font-size: 20px;
}


.sidebar {
    padding: 20px;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.search-input {
    padding: 10px;
    padding-right: 40px;
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-input:focus {
    border-color: #2695ff;
    color: black;
}

button.clear-button {
    background: red;
    border: 1px solid red;
}

.search-button {
    background: #2695ff;

}

.search-button i {
    font-size: 1rem;
}

.mt-3 {
    margin-top: 1rem;
}

.location-dropdown-list {
    margin-top: 1rem;
}

.location-dropdown {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
}

.location-dropdown:focus {
    border-color: #2695ff;
    color: black;
}

.quick-search {
    font-weight: bold;
}

.location-text {
    font-weight: bold;
}

input.search-input.seach-text.form-control {
    padding: 0px;
    margin: 0px;
    border-left: 1px solid #2695ff !important;
}


@media (max-width: 767.98px) {
    h1.search-auctioneers {
        text-align: center;
    }

    p.showing-page-item {
        text-align: center;
        padding-top: 7px;
    }

    .pagination-container {
        text-align: center;
        padding-bottom: 13px;
    }
}