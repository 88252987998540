.form-group.sorting-dropdowon.d-flex.align-items-center.px-4.pt-3 {
    display: inline !important;
}

.sidebar-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
}
.sidebar-item>div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.sidebar-item>div>svg{
    margin-bottom: 0;
}




.dropdown-container {
    position: relative;
    width:100%;
}

.custom-select {
    appearance: none;
    /* padding: 0.5rem 2rem 0.5rem 1rem; */
    border-radius: 0.375rem;
    border: 1px solid #d1d5db;
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 9.586l3.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.2em 1.2em;
}

.custom-select:focus {
    border-color: #2563eb;
    outline: none;
}


@media (max-width:768px){
    .cuckoo-clock1{
        border:none !important
    }
    .view-three-watch-mobile{
        flex-direction: row;

    }
    .magnifier .magnifier-image{
        min-height: 100px !important;
    }
    .view-three-mobile-box{
        gap: 15px;
    }
    .bid-rates{
        width: 100%;
        text-align: center;
    }
    .view-three-des-mobile{
        font-size: 14px;
        text-align: justify;
        font-weight: 500;
    }
    .view-three-col-one-mobile{
        gap: 5px;
    }
    .image-lots{
        min-height: 100px !important;
    }
    .item-content{
        flex-direction: column;
        gap: 10px;
    }
    .view-one-col-three-mobile{
        width: 100%;
        text-align: center;
    }
    .view-one-col-two-mobile{
        width: 100%;
        text-align: center;
    }
    .view-one-col-five-mobile{
        text-align: center;
        font-size: 16px;
    }
    .view-one-col-six-mobile{
        gap:5px;
    }
}