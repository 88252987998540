.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.ring-loader {
    border: 8px solid rgba(0, 0, 0, 0.1);
    /* Light grey */
    border-left: 8px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* General styles */


.lots-row,
.lots-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.lot-item,
.lot-box-item {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    width: calc(100% / 3 - 20px);
    /* Adjust width as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    text-align: center;
}

.lot-title,
.lot-box-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.lot-image,
.lot-box-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.lot-details,
.lot-box-details {
    margin-top: 10px;
}

.lot-item .magnifier{
    max-height: 3000px;
}

.bid-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.bid-button:hover {
    background-color: #2980b9;
}


.magnifier .magnifier-image{
    min-width: 200px ;
    min-height: 300px;
    object-fit: contain !important;
    height: 300px;
}

.magnifier .magnifier-image:hover{
    transform: scale(1.02);
}

@media only screen and (max-width: 1024px){
    .magnifier .magnifier-image{
        min-width: 100%;
        min-height: 100%;
    }
}

@media (max-width: 767.98px) {

    .lot-item{
        width: 100% !important;
    }
    .magnifier-image{
        object-fit: contain !important;
    }
}