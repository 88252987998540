.subscribe form{
    width: 70%;
    max-width: 100%;
    margin: auto;
}

.subscribe .subscribe-btn{
    width: max-content;
    display: block;
    margin: auto;
}

@media only screen and (max-width: 767px){
    .subscribe form{
        width: 100%;
    }
}