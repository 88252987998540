.information {
    border-top: 1px solid;
}

.contact-us {
    padding: 20px;
}

.faq-section {
    margin-top: 20px;
}

.faq-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.faq-item {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;

    transition: background-color 0.3s;
}

.faq-item:hover {
    background-color: #e0e0e0;
}

.faq-question {
    padding: 15px;
    font-weight: bold;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    padding: 15px;
    display: none;
}

.faq-item.active .faq-answer {
    display: block;
}

.faq-arrow {
    font-size: 1rem;
    transition: transform 0.3s;
}

.faq-item.active .faq-arrow {
    transform: rotate(180deg);
}

.faq-image {
    width: 60%;
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

.feedback-form {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
}

.feedback-form .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.feedback-form .form-control {
    flex: 1;
    margin-right: 10px;
}

.feedback-form .form-control:last-child {
    margin-right: 0;
}

.feedback-form textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.feedback-form .btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.contact-us-form-contact {
    background: #2695ff;
    color: #fff;
    border: 1px solid;
    padding: 10px;
    border-radius: 12px;
}






.contact-us .form-group {
    position: relative;
    margin-bottom: 1rem;
    /* Adjust margin to ensure space for error messages */
}

.form-error {
    color: red;
    font-size: 0.875rem;
    position: absolute;
    bottom: -1.5rem;
    /* Adjust to place below input */
    left: 0;
}

.feedback-confirmation {
    font-size: 1rem;
    color: green;
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
}

.faq-answer {
    margin-top: 1.5rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
}

.faq-arrow {
    margin-left: 0.5rem;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;

    cursor: pointer;
    border-radius: 4px;
}

.form-group.contact-form-fileds {
    margin-bottom: 40px !important;
}