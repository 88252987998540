.blog h1 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.5rem;
}


.blog .blog-img .img {
  width: 350px;
  height: 200px;
  object-fit: contain;
}

.blog .blog-body h5 {
  color: #2695ff;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.5rem;
}

.blog .blog-body p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5rem;
}

.blog .blog-body .readmore-btn {
  color: #2695ff;
  font-weight: 700;
}

/* single blog */
.blog-single h1{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 20px;
  letter-spacing: normal;
}

.blog-single .img{
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.blog-single .row{
  width: 70%;
  margin: auto;
}

.blog-single p{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: normal;
}

@media only screen and (max-width: 768px) {
  .blog-single .row{
    width: 100%;
  }
}