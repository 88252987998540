
.pagination .pagi {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  
  .pagination button {
    color: #6c757d;
    cursor: pointer;
    padding: 4px 6px;
  }
  
  .pagination span {
    color: #fff;
    background-color: #007bff;
    padding: 5px 10px;
  }