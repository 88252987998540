.main-header-one {
  padding: 0 !important;
  border-bottom: 1px solid #6b7280;
}

.navbar-brand {
  display: none;
}

.header-list {
  color: #838383;
}

.navbar a {
  color: #838383 !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

.main-header {
  padding: 5px 0px 0px 0px;
}

.navbar .dropdown a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: #555555 !important;
  text-decoration: none;
  padding: 7px;
}

.navbar .dropdown a svg {
  font-size: 20px !important;
}

.navbar .login-link {
  font-weight: 800;
  color: #000 !important;
}

.header-item {
  margin-right: 15px;
}

/* p.login-bidder {
  padding-left: 79px;
} */

.login-bidderr img {
  width: 100px;
  height: 100%;
}

.header-item-list.d-flex {
  padding-left: 110px;
}

.header-item:last-child {
  margin-right: 0;
}

.full-width-hr {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.goog-te-gadget .goog-te-combo {
  border: 1px solid #878787;
}

.goog-te-gadget {
  display: flex;
  flex-direction: column;
  font-size: 0px !important;
}

.goog-te-gadget span a {
  display: flex;
  display: none;
}

form .header-searching {
  width: 300px;
  border-right: 1px solid #2695ff !important;
}

form .zip-code {
  width: 120px;
  border-right: 1px solid #2695ff !important;
}

.suggestions-container {
  top: 70px;
  z-index: 999;
  height: 450px;
  overflow-y: scroll;
}

.suggestions-container ul li {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}

.suggestions-container ul li img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.suggestions-container .close-icon {
  position: absolute;
  right: 0;
}

.search-result .card {
  width: 23%;
  height: 400px;
}

.search-result .card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.suggestions-list {
  position: absolute;
  top: 80px;
  background: #f9f9f9;
  font-size: 16px;
  text-transform: capitalize;
  padding: 10px 0;
  z-index: 999;
  font-weight: 500;
  width: 250px;
  min-height: 100px;
  border: 1px solid #dddddd;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Add this to your Header.css */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  animation: fade-in 2s linear;
  -webkit-animation: fade-in 0.1s linear;
}

.pagination .pagi {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.pagination button {
  color: #6c757d;
  cursor: pointer;
  padding: 4px 6px;
}

.pagination span {
  color: #fff;
  background-color: #007bff;
  padding: 5px 10px;
}

.search-btn:focus-visible,
.search-btn:focus {
  box-shadow: none !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.suggestions-list .suggestion-item {
  padding: 0 10px;
}

.suggestions-list .suggestion-item:hover {
  background-color: #f1f1f1;
  padding: 0 10px;
}

@media only screen and (max-width: 1024px) {
  .login-bidderr {
    width: 70px;
  }
}

@media only screen and (max-width: 992px) {
  .main-header-one {
    border-bottom: none;
  }

  .main-header-one .container {
    padding: 0 15px;
  }

  .close-btn {
    width: 100%;
    font-size: 16px;
    text-align: end;
  }

  .close-btn svg {
    font-size: 20px !important;
  }

  .navbar-brand {
    display: block;
    width: 70px;
  }

  .navbar-collapse {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 80%;
  }

  .navbar-collapse .navbar-nav {
    position: absolute;
    background: #fff;
    z-index: 1000;
    padding: 10px 20px 30px;
    width: 60%;
    align-items: start !important;
    animation: bounce-in-left 2s ease;
    -webkit-animation: bounce-in-left 2s ease;
  }
  @keyframes bounce-in-left {
    0% {
      opacity: 0;
      transform: translateX(-500px);
      -webkit-transform: translateX(-500px);
      -moz-transform: translateX(-500px);
      -ms-transform: translateX(-500px);
      -o-transform: translateX(-500px);
    }
    60% {
      opacity: 1;
      transform: translateX(30px);
      -webkit-transform: translateX(30px);
      -moz-transform: translateX(30px);
      -ms-transform: translateX(30px);
      -o-transform: translateX(30px);
    }
    80% {
      transform: translateX(-20px);
      -webkit-transform: translateX(-20px);
      -moz-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      -o-transform: translateX(-20px);
    }
    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
  }

  .header-categories {
    font-size: 12px;
  }

  .filter-mobile {
    display: none;
  }

  .navbar-collapse .navbar-nav .dropdown {
    margin: 0 !important;
  }

  .login-drop {
    position: absolute !important;
  }

  .login-bidderr {
    display: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .search-btn {
    padding: 0 5px !important;
  }

  .main-header,
  .main-header .row,
  .main-header .row .header-two-container,
  .main-header .row .header-two-container .filter-mobile {
    width: 100%;
    overflow: visible;
  }

  input.header-searching.form-control,
  form .zip-code {
    width: 100%;
  }

  .navbar-collapse .navbar-nav {
    width: 90%;
  }

  button.view-all-btn {
    margin: auto;
  }

  .header-last .row {
    overflow-x: scroll;
  }
}

@media (max-width: 767.98px) {
  .header-item:last-child {
    margin-bottom: 0;
  }

  p.login-bidder {
    padding-left: 0;
  }

  .header-item b {
    padding-left: 0;
  }

  .header-item-list.d-flex {
    padding-left: 0px;
    text-align: center;
    padding-top: 15px;
  }

  .header-item:last-child {
    margin-bottom: 0;
  }

  button.filter-mobile.search-btn.h-\[57px\].text-black.px-5.text-white.max-sm\:border-0.max-sm\:rounded-0.btn.btn-primary {
    margin-bottom: 10px !important;
    border-radius: 12px !important;
    padding: 0 !important;
    max-height: 50px !important;
    font-size: 20px !important;
  }

  .px-5.d-flex {
    padding-left: 0px;
  }

  .categories-list {
    padding-top: 0px;
    color: #fff;
    gap: 0;
    text-align: center;
  }

  .last-header {
    margin-top: 10px;
  }

  button#dropdown-category {
    width: 100%;
    border-radius: 50px;
  }

  p.header-categories a {
    font-weight: 400;
    font-size: 16px;
  }

  .login-bidder {
    display: none !important;
  }

  .login-bidder-headerone {
    display: block !important;
  }

  .mainHeader-nav {
    flex-direction: column;
  }

  .shop-category-mobile::after {
    position: absolute;
    right: 15px;
    top: 60% !important;
    transform: translateY(-50%);
    font-size: 20px;
  }

  .mobile-view-distance::after {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }

  input.header-searching.form-control {
    padding: 20px 10px !important;
  }

  .filter-mobile.bg-\[\#e8e8ed\].px-2.border-x.border-blue-400.rounded-0.py-2.h-full.max-sm\:border-0.dropdown {
    margin-bottom: 10px !important;
    background-color: transparent !important;
  }

  .header-two-container {
    overflow: hidden;
  }

  .header-item-list.d-flex.text-uppercase.ml-md-3.categories-list.items-center.justify-center.gap-5.p-3 {
    gap: 10px !important;
  }

  button.filter-mobile.search-btn.h-\[57px\].text-black.px-5.text-white.max-sm\:border-0.max-sm\:rounded-0.btn.btn-primary {
    border-radius: 0 !important;
  }

  .header-item-list.d-flex.text-uppercase.ml-md-3 {
    padding-top: 0px;
  }

  form.d-inline {
    padding: 7px 0px;
  }

  button#dropdown-miles {
    width: 100%;
    padding: 8px 10px !important;
    text-align: left !important;
  }

  input.form-control {
    padding: 3px;
  }

  button.btn.btn-primary.serach-btn {
    margin-top: 12px;
    padding: 10px 40px 17px 40px;
    border-radius: 20px;
  }

  button#dropdown-category {
    margin-top: 12px;
    border: 1px solid #2695ff !important;
    border-radius: 0px;
    color: black !important;
    font-weight: 500 !important;
  }

  .shop-category {
    text-align: left !important;
  }

  .header-two-mobile {
    flex-direction: row;
  }
}

.px-5.d-flex {
  padding-left: 170px;
}

.categories-list {
  padding-top: 10px;
  color: #fff;
  gap: 20px;
}

button#dropdown-category {
  background: #fff;
  color: #2695ff;
  border: 1px solid;
  border-radius: 25px 0 0 25px;
  padding: 8px;
}

input.form-control {
  border-radius: 0px;
}

button#dropdown-miles {
  background: #fff;
  color: #2695ff;
  padding: 8px 43px;
  border-left: 0px !important;
  border-radius: inherit;
}

button.btn.btn-primary.serach-btn {
  background: #2695ff;
  border: 0px !important;
  border-radius: 0px 22px 22px 0px;
  padding: 10px 20px;
}

.search-btn {
  padding: 0 3rem;
}

.header-two-container.d-flex.border-\[1\.5px\].border-blue-400.rounded-3xl.p-0 {
  max-height: 57px;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.header-categories a {
  color: #ffff;
  list-style: none;
  text-decoration: none;
}

.header-item.dropdown {
  padding-bottom: 15px;
}

.main-header-wrapper {
  position: relative;
}

.header-item-list {
  display: flex;
  align-items: center;
}

.avatar-dropdown {
  margin-left: 20px;
}

.avatar-toggle {
  cursor: pointer;
}

.avatar {
  border-radius: 50%;
}

input.header-searching.form-control {
  padding: 20px 23px !important;
}

.header-categories a:hover {
  color: lightgray !important;
}

#dropdown-category:focus,
.dropdown-miles:focus {
  box-shadow: none !important;
}
