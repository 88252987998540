* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.detail-section label{
 font-size: 18px;
 font-weight: 600;
 letter-spacing: normal;
 width: 40%;
}

.detail-section input ,
.detail-section select ,
.detail-section textarea {
 font-size: 16px;
 font-weight: 400;
 letter-spacing: normal;
 border: 1px solid #ccc;
 padding: 8px;
 border-radius: 3px;
 -webkit-border-radius: 3px;
 -moz-border-radius: 3px;
 -ms-border-radius: 3px;
 -o-border-radius: 3px;
 width: 100%;
}










.name h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.name p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.info-btn .MuiToggleButtonGroup-grouped {
  width: 9rem !important;
}

.info-btn .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #fff !important;
  background-color: #006de2 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1dur2uz-MuiPaper-root-MuiDialog-paper {
  width: 25%;
}

/* .auction-info p{
    font-weight: 500;
} */

.lots-section input {
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  padding: 6px 10px;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.text-center.self-center.flex.items-center.justify-center.hi.css-1ex1afd-MuiTableCell-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-bsph2u-MuiTableCell-root {
  width: 25% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiFormControl-root.MuiTextField-root.css-is1lxd-MuiFormControl-root-MuiTextField-root {
  margin: 0px !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeMedium.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  background: #fff;
  color: #2695ff;
}

.tables-title-auctioner {
  font-weight: 900 !important;
  font-size: 25px !important;
}
