.about-hibis-text-btn {
    /* background: #2695ff; */
    color: #fff;
    border: 1px solid;
    padding: 11px 30px 11px 30px;
    border-radius: 12px;
}

/* .about-hibis-text.px-3.pt-2.pb-5 :hover {
    background: aqua;
}

.about-hibis-text.px-3.pt-2.pb-4 :hover {
    background: aqua;
} */

.abouthibibis ol li {
    list-style: auto;
}

.abouthibibis li {
    margin-left: 10px;
}